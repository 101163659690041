<template>
  <div class="home">
    <top-nav @menu_data="getMenuData" :isARClogo="isARClogo" />
    <div class="part1">
        <div class="part1BookDemo">
            <!-- <iframe data-v-12f6728c="" id="player" frameborder="0" allowfullscreen="allowfullscreen" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" src="https://www.youtube.com/embed/tySSgaYyeeU?&autoplay=1&mute=1&loop=1&showinfo=0&color=white&iv_load_policy=3&playlist=tySSgaYyeeU" class="video">
            </iframe> -->
             <!-- <video
              src="./../../assets/imgs/app/dragonVR/DragonVR.mp4"
              ref="video"
              autoplay
              loop="true"
              type="video/mp4"
              controlslist="nodownload"
              class="video"
            ></video> -->
              <div class='demo'>
                <video-player class="video-player vjs-custom-skin"
                              ref="videoPlayer"
                              :playsinline="true"
                              :options="playerOptions">
                </video-player>
              </div>
            <div class="videoCover">
            </div>
        </div>
        <div class="text">
            <div class="title">
                Off-the-plan VR Specialist
            </div>
            <div class="content">
                Bringing off-the-plan properties to life
            </div>
            <a href=" " onclick="window.open('https://dragonvr.au', '_system'); return false;">
            <button >View Website</button>
            </a>
        </div>
    </div>
    <div class="part2">
        <div class="part2Content">
            <img src="./../../assets/imgs/app/dragonVR/p1.png" class="p2"/>
            <div class="miniImg">
                <div class="miniCol" v-for="i,index in miniContent" :key="'mini'+index">
                    <img :src="i.img" class="mini"/>
                    <div class="miniText">{{i.miniText}}</div>
                </div>
            </div>
            <div style="text-align:left">
                <img src="./../../assets/imgs/app/dragonVR/minicon1.png" class="pic"/>
            </div>
            <h1>About DragonVR</h1>
            <p>With a passionate team of virtual reality developers, interior designers, UI/UX artists and software engineers, DragonVR brings 2D floorplans to life through advanced 3D rendering and VR technologies.</p>
            <!-- <p>View more</p> -->
            <br/>
            <div>
                <p>
                    DragonVR offers a fast turnaround time on projects and is cost effective, providing substantial savings in comparison to alternate display solutions. Virtual property tours allow developers, house and land builders and agents to showcase upcoming properties prior to construction, enhancing sales appeal. 
                </p>
                <br/>
                <p>
                    With project marketing at the core of the products design, the DragonVR team seamlessly collaborates with vendors, creating state of the art VR experiences across all digital touch points and customises interiors to support the developer’s vision.
                </p>
            </div>
        </div>
    </div>
    <!-- <div class="recognised">
      <recognised/>
    </div> -->
    <div class="part2RecoginizedIcons">
      <div class="part2-topcontent">
        <trusted></trusted>
      </div>
    </div>
    <more-features></more-features>
    <div class="part5">
        <div class="title">
            Explore more about our VR products
        </div>
        <div class="ViewButtonBar" style="margin:auto;width:325px">
          <a href=" " onclick="window.open('https://dragonvr.au', '_system'); return false;">
            <div class="button">
                View Website<img src='./../../assets/imgs/app/arcanite/vector.png' class="vector">
            </div>
          </a>
        </div>
    </div>
    <other-product></other-product>
    <footer-nav />
    <menu-bar :isShow="isShow" @changeIsShow="changeShow" />
  </div>
</template>

<script>
import OtherProduct from '../PC/OtherProduct.vue';
// import OtherProduct from '../PC/OtherProduct.vue';
export default {
  data() {
    return {
      showMenuFlag: "0",
      isShow: false,
      isARClogo: true,
      part5MiddleNumber:2,
      // part5LeftNumber:1,
      // part5RightNumber:3,
      part5All:3,
      miniContent:[{img:require('@/assets/imgs/app/dragonVR/miniImg1.png'),miniText:'VR developers'},
      {img:require('@/assets/imgs/app/dragonVR/miniImg2.png'),miniText:'UI/UX designer'},
      {img:require('@/assets/imgs/app/dragonVR/miniImg3.png'),miniText:'Interior designer'},
      {img:require('@/assets/imgs/app/dragonVR/miniImg4.png'),miniText:'Software engineers'}],
      playerOptions: {
          //播放速度
          // playbackRates: [0.5, 1.0, 1.5, 2.0], 
          //如果true,浏览器准备好时开始回放。
          autoplay: true, 
          // 默认情况下将会消除任何音频。
          // muted: false, 
          // 导致视频一结束就重新开始。
          loop: true, 
          // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          preload: 'auto', 
          // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          aspectRatio: '17:10',
          // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          fluid: true,
          sources: [{
              //类型
              type: "video/mp4",
              //url地址
              src: require('./../../assets/imgs/app/dragonVR/DragonVR.mp4')
          }],
          notSupportedMessage: '此视频暂无法播放，请稍后再试',
          controlBar: {
              timeDivider: false,
              durationDisplay: false,
              remainingTimeDisplay: false,
              //全屏按钮
              fullscreenToggle: false,  
          },
      }
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    // this.handleScroll();
    // this.handleFirstPara();
  },
  methods: {
    goRouter(path) {
      this.$router.push(path);
    },
    getMenuData(val) {
      val == "1" ? (this.isShow = true) : (this.isShow = false);
    },
    changeShow() {
      this.isShow = false;
    },
    handleClickDisplay() {
      this.isMove = true;
    },
    changePic(p){
      this.part5MiddleNumber=p
    },
  },

  components: {
    "top-nav": (resolve) => require(["./TopNav.vue"], resolve),
    "footer-nav": (resolve) => require(["./FooterNav.vue"], resolve),
    "menu-bar": (resolve) => require(["./MenuBar.vue"], resolve),
    "trusted":(resolve) => require(["./Trusted.vue"], resolve),
    // "recognised":(resolve) => require(["./RecognisedDragonVR.vue"], resolve),
    "more-features":(resolve) => require(["./MoreForVR.vue"], resolve),
    "other-product":(resolve) => require(["./OtherProduct.vue"], resolve),
  },
};
</script>
<style lang="scss" scoped>
.home{
  text-align: center;
  display: block;
  width: 100vw;
  max-width: 428px;
  min-width: 320px;
  margin: auto;
}

.part1{
    width: 100%;
    height: 100vh;
    margin:auto;
    overflow: hidden;
      .text{
      margin: auto;
      // width: 239px;
      height: 146px;
      z-index: 20;
      display: inline-block;
        align-content: center;
        text-align: center;
        vertical-align: center;
        position: relative;
        top:-50vh;
        // left: 40%;
      .title{
        margin: auto;
        // width: 239px;
        flex-grow: 0;
        font-family: Poppins-Bold;
        font-size: 26px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: center;
        color: #fff;

      }
      .content{
        margin: auto;
        // width: 200px;
        margin-top: 5%;
          flex-grow: 0;
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
      a{
        display: inline-block;
        align-content: center;
        text-align: center;
        button{
          margin: auto;
          margin-top:30px;
          width: 141px;
          height: 42px;
          flex-grow: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 10px;
          // padding: 10px 16px;
          border-radius: 5px;
          background-color: #1890ff;
          flex-grow: 0;
          font-family: Poppins-Bold;
          font-size: 16px;
          // font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
        }
      }
      
  }
}

.part1BookDemo{
//   width: 100vw;
  margin: 0;
  height: 100vh;
  position: relative;
  overflow: hidden;
//   padding-bottom: 56.25%;
    padding-right: 177%;
  // display: relative;
//   background-color: blue;
  // z-index: -99;
// position: relative;
  z-index: 0;
    display: inline-block;
  align-content: center;
  text-align: center;
  vertical-align: center;

}
// .video{
//     position: absolute;
//     // z-index: -99;
//     top: 70px;
//     left: -40vh;
//     // width: 100%;
//     // height: 100%;
// }
.videoCover{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: -20vh;
    background-color: rgba(0, 0, 0, 0.2);
}

.vector{
  width: 22px;
  height: 22px;
  flex-grow: 0;
  object-fit: contain;
  margin-left: 10px;
}

.part2{
    width: 100%;
    overflow: hidden;
    padding-bottom: 20px;
    background-color: #f7f9fb;
    padding-top: 48px;
}
.part2Content{
    // margin: 0 16px 0 16px;
    width: 100%;
    .p2{
        width: 100%;
        // height: 195px;
        // margin: 0 0 314.6px;
    }
    .miniImg{
        margin:auto;
        margin-top: 12px;
        margin-bottom: 32.5px;
        width: 344px;
        display: flex;
        .miniCol{
          margin:0 3px 0 3px;
            width: 80px;
            text-align: center;
            .mini{
                width: 40px;
                height: 40px;
            }
            .miniText{
                margin-top: 10px;
                width: 80px;
                font-family: Poppins;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: #062440;
            }
        }
    }
    h1{
      margin: 0 26px 0 26px;
      flex-grow: 1;
      font-family: Poppins-Bold;
      font-size: 26px;
      // font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: left;
      color: #062440;
      margin-bottom: 20px;
    }
    p{
      margin: 0 26px 0 26px;
      flex-grow: 1;
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #314455;
    }
}

.pic{
  width: 80px;
  height: 80px;
  margin-left: 10px;
}

.ClientTest{
  .title{
    margin:auto;
    margin-top:50px;
    margin-bottom: 15px;
    width: 343px;
    height: 84px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 36px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #062440;
  }
  img{
    margin: auto;
    width: 375px;
    height: auto;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
    // align-items: flex-start;
    // gap: 20px;
    // margin: 5px 10px 24.4px 26px;
    // padding: 20px;
    // border-radius: 5px;
    // box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05), 0 15px 30px 0 rgba(0, 0, 0, 0.07);
    // border: solid 1px #e6ebf2;
    // background-color: #fff;
  }
  .dogs{
    margin: auto;
    width: 70px;
    height: 10px;
    display: flex;
    .dogBlue{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #1890ff;
      display: inline-block;
      margin:0 10px 0 10px;
    }
    .dogWhite{
      width: 10px;
      height: 10px;
      background-color: #d8d8d8;
      border-radius: 50%;
      display: inline-block;
      margin:0 10px 0 10px;
    }
  }
}

.part5{
    margin: auto;
    width: 100%;
    height: 196px;
    background-image:url('./../../assets/imgs/app/dragonVR/p5.png') ;
    background-size: 100%;
    .title{
        margin: auto;
        padding-top: 20px;
        margin-bottom: 45px;
        width: 325px;
        // height: 160px;
        align-self: stretch;
        flex-grow: 0;
        font-family: Poppins-Bold;
        font-size: 26px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
    .ViewButtonBar{
		padding-bottom: 20px;
		.button{
		    flex-grow: 0;
		    font-family: Poppins-Bold;
		    font-size: 16px;
		    // font-weight: bold;
		    font-stretch: normal;
		    font-style: normal;
		    // line-height: 1.38;
		    letter-spacing: normal;
		    text-align: center;
		    color: #fff;
		    width: 171px;
		    height: 42px;
		    flex-grow: 0;
		    display: flex;
		    flex-direction: row;
		    justify-content: center;
		    align-items: center;
		    // gap: 10px;
		    padding: 5x;
		    border-radius: 5px;
		    background-color: #062440;
		}
	}
}

  .demo{
    display: inline-block;
    width: 177vh;
    height: 100vh;

    position: absolute;
    // top: 70px;
    left: -40vh;
  }

  // .recognised{
  //   display: flex;
  //   justify-content: flex-start;
  //   margin: 0 26px 0 26px;

  // }
  .part2RecoginizedIcons{
    margin: 40px auto;
    // width: 1210px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items:center;
    gap: 30px;
  }


</style>

